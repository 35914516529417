<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.photo"
            :text="avatarText(userData.full_name)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.full_name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
              <div
                v-if="userData.job_description"
              >({{ userData.job_description }})</div>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ resolveUserRoleText(userData.role) }}
            </td>
          </tr>
          <tr
            v-if="isManager"
          >
            <th class="pb-50">
              <feather-icon
                icon="LogInIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Last login</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.last_login }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ArrowRightIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Date joined</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.date_joined }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    return {
      isManager: userRole === 'platform-manager',
    }
  },
  setup() {
    const { resolveUserRoleVariant, resolveUserRoleText } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleText,
    }
  },
}
</script>

<style>

</style>
